import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ClassPreview from "../../components/class-preview/class-preview"
import AnmeldungCtaSection from "../../components/anmeldung-cta-section/anmeldung-cta-section"
import { graphql, useStaticQuery } from "gatsby"
import Accordion from "../../components/accordion/accordion"

const MotorradPage = () => {
  const { a, a1, a2, am } = useStaticQuery(
    graphql`
      query {
        am: file(relativePath: { eq: "klasse-am-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a: file(relativePath: { eq: "klasse-a-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a1: file(relativePath: { eq: "klasse-a1-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        a2: file(relativePath: { eq: "klasse-a2-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return <Layout>
    <SEO title="Motorradführerscheine im Überblick" description={'Ab aufs Bike – wir machen dich ganz easy zum/zur Rider:in. Alle Infos über deinen Motorradführerschein A, A1, A2 oder AM gibt’s hier.'}/>
    <SolidHero primary title={"Motorradführerschein"} subtitle={() => "Alle Motorrad-Klassen im Überblick"}></SolidHero>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/motorrad/klasse-a/"} title={"Klasse A"}
                  text={"A wie Alle. Mit dem Klasse A Motorradführerschein ist zukünftig kein Bike vor dir sicher, egal ob Klasse AM, A1 oder A2 – fahren darfst du sie alle. Vorausgesetzt, du bist mindestens 24 Jahre alt. Im Detail bedeutet das: Krafträder mit Beiwagen, bis zu (oder über) 500 ccm Hubraum und der bauartbedingten Höchstgeschwindigkeit von maximal 45 km/h. Dreirädrige und vierrädrige Kraftfahrzeuge, zum Beispiel Trikes und Quads, Leistung über 35 kW und über 50 ccm Hubraum. Beschränkungen, wie bei den anderen Klassen gibt’s mit dem Klasse A Führerschein keine. All ride!"}
                  imageFluid={a.childImageSharp.fluid} imageAlt={'Klasse A'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/motorrad/klasse-a1/"} title={"Klasse A1"}
                  text={"Besonders Neulinge nennen ihn in der Regel 1A. Mit dem Klasse A1 Motorradführerschein kannst du als Einsteiger entspannt aufsitzen – und das schon ab 16 Jahren. Dein Bike darf hier bis zu 125 ccm Hubraum und eine Maximalleistung von 11 kW mitbringen. Das muss sich dabei allerdings nicht mal auf nur zwei Räder beschränken: Sogar Trikes darfst du mit dem A1-Führerschein fahren, sofern sie die 15 kW auf der Straße nicht überschreiten. Übrigens: Mit dem A1 gibt’s zwei Führerscheine in einem – bei bestandener Prüfung gehört dir zusätzlich auch die AM-Fahrerlaubnis."}
                  imageFluid={a1.childImageSharp.fluid} imageAlt={'Klasse A1'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/motorrad/klasse-a2/"} title={"Klasse A2"}
                  text={"Wenn du schon 18 (oder sogar drüber) bist, darfst du dich freuen – und dafür die Maschine richtig laut aufheulen lassen. Denn seit 2013 bist du dann zum Motorradführerschein der Klasse A2 berechtigt. Und der wiederum berechtigt dich neben allen Annehmlichkeiten der Klasse A1 vor allem auch zum Führen der ganz großen Maschinen. Bei Motorrädern mit bis zu 200 km/h Höchstgeschwindkeit und maximal 35 kW bedeutet das für dich natürlich jede Menge Freiheit und ordentlich Fahrtwind. Übrigens auch, wenn du vorher gar keinen anderen Führerschein besessen hast."}
                  imageFluid={a2.childImageSharp.fluid} imageAlt={'Klasse A2'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/motorrad/klasse-am/"} title={"Klasse AM"}
                  text={"DAS Sinnbild für den ersten großen Wunsch nach Unabhängigkeit: Der Roller-, bzw. Mopedführerschein. Wenn man es ganz genau nähme, müsste er auch noch die Bezeichnung „Leichtkraftfahrzeugführerschein“ tragen – schließlich schließt er nicht nur zwei- und dreirädrige Krafträder ein, sondern auch gleich einige vierrädrige. Bei den zweirädrigen wären das zum Beispiel Mopeds, Roller und Mokicks. Hier gilt die bauartbedingte Höchstgeschwindigkeit von 45 km/h, Hubraum von maximal 50 Kubikzentimern und höchstens 4 kW Leistung. Natürlich wahlweise auch mit Elektromotor. Bei den vierrädrigen Vehikeln bleibt die Höchstgeschwindigkeit gleich, die Leistung jedoch ist mit maximal 6 kW bemessen."}
                  imageFluid={am.childImageSharp.fluid} imageAlt={'Klasse AM'}></ClassPreview>
    <AnmeldungCtaSection claimIndex={0}></AnmeldungCtaSection>

    {/*<Accordion questionsAnswers={[*/}
    {/*  { "question": "test", "answer": "test" },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  }*/}
    {/*]}></Accordion>*/}
  </Layout>
}


export default MotorradPage
