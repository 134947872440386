import React from "react"

import "./class-preview.sass"
import Link from "gatsby-link"
import Img from "gatsby-image"
import PropTypes from 'prop-types'

const ClassPreview = ({imageFluid, imageAlt, title, text, linkPath, linkText}) => {


  return <section className={"_13 _6c"}>
    <div className="_15">
    <div className="_8m">
    <div className={"_8n"}>
      <Link to={linkPath}>
    <Img alt={imageAlt} className={"_8o"} fluid={imageFluid}></Img>
      </Link>
    </div>
    <div className={"_8p"}>
      <Link to={linkPath}>
      <h3 className={"_4e _at"}>{title}</h3>
      </Link>
      <p className={"_au"}>{text}</p>
      <Link to={linkPath} className={"_8q _4t"}>{linkText}</Link>
    </div>
    </div>
    </div>
  </section>

}


ClassPreview.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  imageFluid: PropTypes.any.isRequired,
  imageAlt: PropTypes.string.isRequired
}

export default ClassPreview
